import {delay} from "redux-saga/effects";
import {put, call} from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import authService from "../../services/authService";
// import vendorService from "../../services/vendorService";
import {notification} from "antd";
import * as appNotification from "../../utils/notificationHelper";
import createGuest from "cross-domain-storage/guest";
import history from "../../utils/history";
import { encodeBase64 } from "../../utils/utils";
import * as actions from "../actions/index";
import {FRONT_SSO_URL} from "../../config/apiConfig";
const CryptoJS = require("crypto-js");

export function* logoutSaga(action) {
  yield call([localStorage, "removeItem"], "token");
  yield call([localStorage, "removeItem"], "expirationDate");
  yield call([localStorage, "removeItem"], "userId");
  yield call([localStorage, "removeItem"], "role");
  yield call([localStorage, "removeItem"], "name");
  yield call([localStorage, "removeItem"], "email");
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

export function* changePasswordSaga(action) {
  const {data} = action;
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield authService.changeVendorPassword(data);
    if (response.code >= 0) {
      appNotification.DisplayNotification(
        "success",
        response.description,
        "Success"
      );
      yield put(actions.closeSideBar());
    } else
      appNotification.DisplayNotification(
        "error",
        response.description,
        "Error"
      );
  } catch (error) {
    if (error) appNotification.DisplayNotification("error", error, "Error");
  }
  yield put(actions.sideBarStopLoading());
}

export function* updateUserProfileSaga(action) {
  const {data} = action;
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield authService.updateUserProfile(data);
    yield put(actions.sideBarStopLoading());
    if (response.code >= 0) {
      appNotification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield call([localStorage, "removeItem"], "name");
      yield localStorage.setItem("name", `${data.firstName} ${data.lastName}`);
      yield put(actions.closeSideBar());
      return;
    } else
      appNotification.DisplayNotification(
        "error",
        "Error",
        response.description
      );
  } catch (error) {
    appNotification.DisplayNotification(
      "error",
      "Error",
      error.error_description
    );
  }
  yield put(actions.sideBarStopLoading());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());

  var bazStorage = yield createGuest(FRONT_SSO_URL);
  yield bazStorage.get("USER", (error, value) => {
    if (value) {
      const bytes = CryptoJS.AES.decrypt(value.toString(), "smartware.spa");
      const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (!response) {
        history.push("/vendor/denied"); //no access
      } else if (
        response.modules.includes("QUICKTELLER_FUEL") &&
        (["VENDOR_USER", "VENDOR_ADMIN", 'VENDOR_SUPER_ADMIN', 'LOGISTIC', 'PROCUREMENT'].includes(response.type))
      ) {
        let fuelUser = jwt_decode(response.access_token);
        const expirationDate = new Date(
          new Date().getTime() + fuelUser.exp * 1000
        );

        localStorage.setItem("token", response.access_token);
        localStorage.setItem("expirationDate", expirationDate);

        // IMPORTANT: always after setting the token in storage
        authService.getUserBasicProfile().then((profileData) => {
          const permissions = Object.keys(profileData.payload.permissions).join(",");
          localStorage.setItem("ucc", encodeBase64(permissions)); // actually 1-element for vendor-users

          let {
            // userLevel: role,
            userEmail: email,
            firstName,
            lastName,
            // id,
            // sub,
            userTypeId
          } = response;
          localStorage.setItem('modules', response.modules);
          localStorage.setItem('type', response.type);
          localStorage.setItem("role", response.type);
          localStorage.setItem("name", `${firstName} ${lastName}`);
          localStorage.setItem("email", email);
          localStorage.setItem("firstName", firstName);

          localStorage.setItem("vendorId", userTypeId);// fix for user-id issue
        // put(actions.checkAuthTimeout(fuelUser.exp));
        // eslint-disable-next-line no-restricted-globals
        // location.href("/admin");
          
          put(actions.authSuccess(response.access_token, response.refresh_token));
          history.push("/vendor");
        }).catch(e => {
          localStorage.clear();
          history.push("/vendor");
        });
      } else {
        history.push("/vendor/denied"); //no access
      }
    } else {
      window.location.href = FRONT_SSO_URL + "/signin?r=isw-vendor";
    }
  });
  //catch (error) {
  //   if (error.error_description !== undefined) {
  //     notification.error({
  //       message: "Login Error",
  //       description: error.error_description
  //     });
  //   } else {
  //     notification.error({
  //       message: "Network Error",
  //       description: "Cannot reach the API endpoint."
  //     });
  //   }

  //   return yield put(
  //     actions.authFail(
  //       error.response != null ? error.response.data.error : "Network Error"
  //     )
  //   );
  // }
  // });
}

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(
      localStorage.getItem("expirationDate")
    );
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const userId = yield localStorage.getItem("userId");
      yield put(actions.authSuccess(token, userId));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}

export function* getVendorUserProfile(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield authService.getUserProfileById(action.userId);
    if (response.code === 1 || response.code === 0) {
      yield put(actions.getVenderProfileSuccess(action.payload));
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {
    notification.DisplayNotification(
      "error",
      "Error",
      "Unexpected error occurred."
    );
  }
  yield put(actions.pageStopLoading());
}
